.container {
  position: relative;
}

.input {
  display: flex;
  align-items: center;
  width: 100%;
  height: 34px;
  padding: 0 24px;
  box-sizing: border-box;
  border: 1px solid #E6E6E6;
  outline: none;
  background-color: #fff;
  font-size: .9375rem;
  color: #333;
  line-height: .9375rem;
  border-radius: 24px;
}

.container.shadows .input {
  box-shadow: 0 1px 5px 2px rgba(0,0,0,0.02);
}

.input:hover {
  border-color: #C6C6C6;
}

input.active, .input:not(.active):focus {
  border-color: #C6C6C6;
}

.input::placeholder {
  color: #333;
  opacity: 0.5;
}
.input::-ms-input-placeholder {
  color: #333;
  opacity: 0.5;
}

.icon {
  display: none;
  position: absolute;
  z-index: 1;
  right: 12px;
  top: 50%;
  margin-top: -.7rem;
  font-size: 1.4rem;
  color: #E0E0E0;
}

.targets {
  position: absolute;
  z-index: 2;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: 10px;
  padding: 10px 0;
  box-sizing: border-box;
  border: 1px solid #E6E6E6;
  border-radius: 14px;
  background-color: #fff;
  overflow: hidden;
}

.container.shadows .targets {
  box-shadow: 0 1px 5px 2px rgba(0,0,0,0.02);
}

.targets .title {
  display: block;
  margin: 5px 0 10px 0;
  padding: 0 15px;
  font-size: .85rem;
  color: #555;
  font-weight: bold;
}

.targets .toggles {
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid #F0F0F0;
}

.targets label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 15px;
  cursor: pointer;
}

.targets label span {
  color: #555;
  font-size: .8rem;
}

.targets .toggles label span {
  font-size: .85rem;
  font-weight: bold;
}

.targets label:hover {
  background-color: #F7F7F7 !important;
}
.targets label:not(.checked):hover span,
.targets label.checked span {
  color: #000 !important;
}

.targets .checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13px;
  height: 13px;
  box-sizing: border-box;
  border: 1px solid #555;
  border-radius: 3px;
  color: #555;
}

.checkbox svg {
  font-size: .7rem;
  color: #000;
}

.targets label.checked .checkbox {
  border-color: #000;
  color: #000;
}
