.container {
  display: flex;
  height: 100%;
  position: relative;
  margin: 0 0 0 30px;
  border-radius: 5px 0 0 0;
  box-shadow: 0 1px 62px 0 rgba(0,0,0,0.09);
  background-color: #fff;
}

.container.preview {
  width: 514px;
  border-radius: 5px 5px 0 0;
}

.actions {
  z-index: 3;
  display: flex;
  align-items: center;
  background-color: #fff;
}

.actions > :not(:first-child) {
  margin-left: .5rem;
}

.actions .deleteIcon {
  font-size: 1.3rem;
  margin-right: .2rem;
}

.actions .view {
  text-decoration: none !important;
}

.section {
  padding: 2rem;
  box-sizing: border-box;
}

.section.left {
  position: relative;
  flex-grow: 1;
  height: 100%;
}

.section.right {
  width: 400px;
  min-width: 400px;
  max-width: 400px;
  height: 100%;
  box-sizing: border-box;
  border-left: 1px solid #F0F0F0;
  background-color: #FBFBFD;
}

.section.right .grantActions {
  height: calc(100% - 44px);
}

.section .title {
  display: flex;
  align-items: center;
  height: 20px;
  margin-bottom: 24px;
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
  line-height: 1.25rem;
  white-space: nowrap;
}

.section .scrollableForm {
  /* scrollable */
  height: calc(100% - 44px);
}

.section .form {
  width: 100%;
  max-width: 520px;
}

.section .form [data-field-preview="true"] [data-field-label] {
  font-size: .8125rem !important;
}

.section .form [data-field-preview="true"] [data-field-value] {
  font-size: .875rem !important;
}

.section .form .formControl {
  margin-bottom: 1rem;
}

.section .form textarea {
  height: 120px;
}

.section .form .formButtons {
  display: flex;
  justify-content: flex-start;
  margin-top: 2rem;
}

.section .form .formButtons > *:not(:first-child) {
  margin-left: 10px;
}

.loading {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  padding: 2rem;
}

.loader {
  display: flex !important;
  justify-content: center;
  width: 100% !important;
}


.project_header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
