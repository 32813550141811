.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.container.inline {
  position: static;
  display: inline-flex;
  align-items: center;
  width: auto;
  height: 30px;
  margin: 0;
}

.loader {
  height: 10px;
  text-align: center;
}

.loader > div {
  background-color: #777;
  height: 100%;
  width: 10px;
  display: inline-block;
  margin: 0 1px;
  border-radius: 50%;
  vertical-align: top;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.loader .r2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.loader .r3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.loader .r4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.loader .r5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scale(0.4) }  
  20% { -webkit-transform: scale(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% { 
    transform: scale(0.4);
    -webkit-transform: scale(0.4);
  }  20% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}
