.container {
  width: 100%;
  user-select: none;
}

.container label {
  display: block;
  margin-bottom: .4rem;
  font-size: .75rem;
  font-weight: bold;
  color: #333;
}

.controlWrapper {
  position: relative;
}

.trigger {
  display: flex;
  justify-content: space-between;
  padding: 7.5px 15px;
  line-height: .75rem;
}

.container:not(.disabled) .trigger {
  cursor: pointer;
}

.trigger .triggerIcon {
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 15px;
  margin-top: -.5rem;
  font-size: 1rem;
  color: #333;
}

.container.open .trigger .triggerIcon {
  transform: rotate(180deg);
}

.trigger .placeholder {
  /* color: #B9B9B9; */
}

.options {
  position: absolute;
  z-index: 5;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 220px;
  margin-top: 10px;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #A0A0A0;
  border-radius: 3px;
  overflow-y: auto;
  user-select: none;
}

.option {
  padding: 7.5px 15px;
  cursor: pointer;
  font-size: .75rem;
  color: #333;
}

.option:hover {
  background-color: #F7F7F7;
  color: #000;
}

.option.selected {
  background-color: #F7F7F7;
  color: #000;
}

.control {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #DFDFDF;
  border-radius: 3px;
  outline: none;
  background-color: #fff;
  font-size: .75rem;
  color: #333;
}

.container.open .control,
.container:not(.disabled) .control:hover {
  border-color: #A0A0A0;
}

.container.disabled .control {
  background-color: #F4F5F7;
}

.preview {
  font-size: .75rem;
  color: #333;
}

.error {
  margin-top: .3rem;
  color: #B60010;
  font-size: .75rem;
}
