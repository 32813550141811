.container {}

.content {
  display: flex;
  height: 100%;
  box-shadow: 0 1px 62px 0 rgba(0,0,0,0.09);
  background-color: #fff;
}

.title {
  display: flex;
  align-items: center;
  height: 20px;
  margin-bottom: 24px;
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
  line-height: 1.25rem;
  white-space: nowrap;
}

.usersActions {
  height: calc(100% - 44px);
}

.left {
  width: 320px;
  min-width: 320px;
  max-width: 320px;
  height: 100%;
  padding: 2rem;
  border-right: 1px solid #F0F0F0;
  box-sizing: border-box;
  background-color: #FBFBFD;
}

.right {
  flex-grow: 1;
  height: 100%;
}

.tabs {
  display: flex;
  flex-grow: 1;
  height: 65px;
  padding: 1rem 2rem 0 2rem;
  border-bottom: 1px solid #F0F0F0;
  user-select: none;
  box-sizing: border-box;
}

.tab {
  position: relative;
  padding: 1rem 0;
  background-color: #fff;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  color: #555;
  white-space: nowrap;
}

.tab:not(:last-child) {
  margin-right: 30px;
}

.tab:hover {
  color: #000;
}

.tab.active {
  color: #0D7997;
}

.tab.active::after {
  content: "";
  position: absolute;
  top: calc(100% + -1px);
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #0D7997;
}

.tabContent {
  height: calc(100% - 65px);
  box-sizing: border-box;
}

.tabContent.spaced {
  padding: 25px 2rem 2rem 2rem;
}

.noUser {
  display: block;
  padding: 10px 5px;
  text-align: center;
  font-size: .875rem;
  color: #A9A9A9;
}
