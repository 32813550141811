.container {
  width: 100%;
  user-select: none;
}

.container label {
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: bold;
}

.container:not(.disabled) label {
  cursor: pointer;
}

.control {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
}

.toggle .mark {
  visibility: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -.4rem;
  margin-left: -.4rem;
  font-size: .8rem;
}

.container:not(.disabled):hover .toggle {
  border-color: #A0A0A0;
}

.container:not(.disabled) input:checked ~ .toggle {
  border-color: #0D7997;
  background-color: #0D7997;
  color: #fff;
}

.container:not(.disabled) input:checked ~ .toggle .mark {
  visibility: visible;
}

.label {
  display: inline-block;
  margin-left: 10px;
  font-size: .75rem;
  color: #333;
}

.error {
  margin-top: .3rem;
  color: #B60010;
  font-size: .75rem;
}
