.container {
  display: flex;
  align-items: center;
}

.icon {
  font-size: 1.5rem;
  margin-right: 8px;
}

.text {
  font-size: 1.125rem;
  color: #333;
}
