.container {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 10px;
}

.container.link {
  font-size: 1rem;
  color: #444;
  text-decoration: none;
}

.container:hover, .container.active {
  cursor: pointer;
}
