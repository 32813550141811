.container {
  height: 100%;
  user-select: none;
}

.search {
  height: 34px;
  margin-bottom: 25px;
}

.menu {
  /* scrollable */
  height: calc(100% - 59px);
  margin-left: -16px !important;
  margin-right: -26px !important;
}

.menu [data-scrollable-content] {
  margin-left: 16px !important;
  margin-right: 26px !important;
}

.add {
  display: flex;
  justify-content: flex-start;
}

.orphansSeparator {
  margin: 15px 10px 15px 10px;
  padding: 20px 0 0 0;
  border-top: 1px solid #E0E0E0;
  font-size: .75rem;
  color: #333;
}

.orphansSeparator [role="tooltip"] {
  width: 290px;
  min-width: 290px;
  max-width: 290px;
}

.loader {
  display: flex !important;
  justify-content: center;
  width: 100% !important;
  margin-bottom: 18px !important;
}

.noResults {
  display: block;
  margin-bottom: 18px;
  text-align: center;
  font-size: .875rem;
  color: #A9A9A9;
}
