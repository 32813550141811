.container {
  height: 100%;
}

.adders {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 18px;
  margin-bottom: 18px;
}

.adders button {
  padding: 0;
  outline: none;
  user-select: none;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  font-size: .9375rem;
  font-weight: bold;
  color: #0C7997;
}

.domains {
  /* scrollable */
  height: calc(100% - 36px);
  user-select: none;
}

.domains.tall {
  height: 100%;
}

.domain {
  display: flex;
  align-items: center;
  margin: 5px 0;
  padding: 0 10px;
  cursor: pointer;
  user-select: none;
  border-radius: 10px;
  color: #333;
}

.domain.selected {
  background-color: #0C7997;
  color: #fff;
}

.domain:not(.selected):hover {
  background-color: #EFEFEF;
  color: #000;
}

.domain .name {
  flex-grow: 1;
  width: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: .875rem;
  padding: .6rem .6rem .6rem 0;
}

.domain .delete {
  font-size: 1.3rem;
}

.modal {
  width: 320px;
}

.modalText {
  margin-bottom: 25px;
  font-size: .85rem;
}

.modalInputContainer {
  display: flex;
  align-items: top;
  margin-bottom: 32px;
}

.modalInputContainer .modalInputPrefix {
  margin-right: 10px;
  font-size: 1rem;
  color: #333;
  line-height: 32px;
}

.modalInputContainer .modalInput {
  flex-grow: 1;
}

.modalButtons {}

.loader {
  display: flex !important;
  justify-content: center;
  width: 100% !important;
}

.busyLoader {
  height: 25px !important;
  margin-left: 10px !important;
}

.noResults {
  display: block;
  padding: 10px 5px;
  text-align: center;
  font-size: .875rem;
  color: #A9A9A9;
}
