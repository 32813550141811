.container {
  overflow: visible;
  background-color: #EFF0F4;
}

.head {
  height: 72px;
  box-sizing: content-box;
}

.body {
  display: flex;
  height: calc(100vh - 72px);
}

.body .side {
  height: calc(100vh - 72px);
  padding: 0 28px 2rem 18px;
  box-sizing: border-box;
  overflow-x: visible;
  overflow-y: auto;
}

.hidden {
  padding: 0 !important;
  animation: hideNav 500ms linear;
  min-width: 0;
  width: 0;

}

@keyframes showNav {
  from { min-width: 0; width: 0; }
  to { min-width: 500px;  width: 500px; }
}

@keyframes hideNav {
  from { min-width: 500px; width: 500px; }
  to { min-width: 0;  width: 0; }
}

@-webkit-keyframes showNav {
  from { min-width: 0; width: 0; }
  to { min-width: 500px;  width: 500px; }
}

@-webkit-keyframes hideNav {
  from { min-width: 500px; width: 500px; }
  to { min-width: 0;  width: 0; }
}

@-moz-keyframes showNav {
  from { min-width: 0; width: 0; }
  to { min-width: 500px;  width: 500px; }
}

@-moz-keyframes hideNav {
  from { min-width: 500px; width: 500px; }
  to { min-width: 0;  width: 0; }
}

.nonHidden {
  animation: showNav 500ms linear;
  display: block;
  width: 500px;
  min-width: 500px;
}

.styledSideMenu {
  position: absolute;
  z-index: 999;
  background-color: #EFF0F4;
}

.body .content {
  flex-grow: 1;
  height: calc(100vh - 72px);
  box-sizing: border-box;
}

.body .content .emptyState {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 1rem;
  box-sizing: border-box;
}

.body .content .emptyState > svg {
  margin-bottom: 25px;
}

.body .content .emptyState > span {
  line-height: 1.25rem;
  font-size: 1.25rem;
  color: #333;
}
