.container {
  height: 100%;
  position: relative;
  margin: 0 0 0 30px;
  padding: 2rem;
  border-radius: 5px 0 0 0;
  box-shadow: 0 1px 62px 0 rgba(0,0,0,0.09);
  background-color: #fff;
  box-sizing: border-box;
}

.container.preview {
  width: 514px;
  border-radius: 5px 5px 0 0;
}

.container.embed {
  margin: 0;
  padding: 0;
  box-shadow: none;
  border: 0;
  border-radius: 0;
}

.editButton {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  min-width: auto !important;
  margin-top: -.8rem !important;
  padding: 0 !important;
  font-size: .95rem !important;
  color: #0C7997 !important;
  font-weight: bold;
}

.editButton > * {
  color: #0C7997 !important;
}

.editButton svg {
  margin-left: 5px;
  font-size: 1.1rem;
}

.container:not(.embed) .editButton {
  right: 2rem;
  top: 35px;
}

.title {
  display: flex;
  align-items: center;
  height: 16px;
  margin-bottom: 16px;
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  line-height: 1rem;
  white-space: nowrap;
}

.title.big {
  display: flex;
  height: 20px;
  font-size: 1.25rem;
  line-height: 1.25rem;
  margin-bottom: 32px;
}

.container.embed .title {
  font-size: .95rem;
}

.section {
  /* scrollable */
  height: calc(100% - 52px);
  flex-grow: 1;
}

.container.embed .section {
  height: 100%;
}

.section .form {
  width: 100%;
  max-width: 520px;
}

.section .form [data-field-preview="true"] [data-field-label] {
  font-size: .8125rem !important;
}

.section .form [data-field-preview="true"] [data-field-value] {
  font-size: .875rem !important;
}

.section .form .formSection {
  margin-bottom: 2rem;
}

.section .form .formSection.passwordSection .passwordLink {
  text-decoration: none;
  color: #0C7997;
  font-size: .8125rem;
  font-weight: bold;
}

.section .form .formSection.passwordSection .passwordLink:hover {
  text-decoration: underline;
}

.section .form .formControl {
  margin-bottom: 1rem;
}

.section .form .formSection:last-child,
.section .form .formControl:last-child {
  margin-bottom: 0 !important;
}

.section .form .formButtons {
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;
}

.section .form .formButtons > *:not(:first-child) {
  margin-left: 10px;
}

.section .form .controlsGroup {
  display: flex;
  justify-content: space-between;
}

.section .form .controlsGroup > * {
  width: calc(50% - 10px);
}

.container.embed .section .form .formSection {
  margin-bottom: 1.6rem;
}

.loader {
  display: flex !important;
  justify-content: center;
  width: 100% !important;
}

.textError {
  margin-bottom: 25px;
  font-size: .85rem;
  color: red;
}
