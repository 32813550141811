.container {
  margin: 2rem auto 0 auto;
  text-align: center;
}

.info {
  display: block;
  margin-bottom: 1rem;
  font-size: 2rem;
}

.link {
  display: block;
  font-weight: bold;
}
