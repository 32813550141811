.container {
  position: relative;
}

.container::after {
  content: "";
  display: block;
  position: relative;
  top: -3px;
  width: 6.4px;
  height: 6.4px;
  border: solid #000;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.container.open::after {
  top: 1px;
  transform: rotate(225deg);
}

.avatar {
  width: 38px !important;
  height: 38px !important;
  margin-right: 8px;
}

.name {
  margin-right: 16px;
  font-size: 1.125rem;
  color: #333;
}

.menu {
  position: absolute;
  z-index: 1000;
  left: 0;
  top: calc(100% - 8px);
  width: 100%;
  padding: 10px 0;
  box-sizing: border-box;
  border: 1px solid #E6E6E6;
  border-radius: 14px;
  background-color: #fff;
  box-shadow: 0 1px 5px 2px rgba(0,0,0,0.02);
}

.menuLink {
  display: block;
  padding: 10px 15px;
  color: #333 !important;
  text-decoration: none !important;
  font-size: 1rem;
}

.menuLink:hover {
  color: #000;
  background-color: #F7F7F7;
}
