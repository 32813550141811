.container {}

.breadcrumbs {
  display: flex;
  align-items: center;
  height: 34px;
  margin-bottom: 15px;
  padding: 0 30px;
  box-sizing: border-box;
}

.content {
  height: calc(100% - 49px);
}
