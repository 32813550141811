.container {
  height: 100%;
}

.search {
  height: 34px;
  margin-bottom: 16px;
}

.adders {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 18px;
  margin-bottom: 18px;
}

.adders button {
  padding: 0;
  outline: none;
  user-select: none;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  font-size: .9375rem;
  font-weight: bold;
  color: #0C7997;
}

.permissions {
  /* scrollable */
  height: calc(100% - 86px);
  user-select: none;
}

.permissions.tall {
  height: calc(100% - 50px);
}

.permissions .group {
  color: #333;
}

.permissions .group .groupTitle {
  position: relative;
  display: flex;
  align-items: center;
  margin: 5px 0;
  padding: 0 48px 0 10px;
  cursor: pointer;
  border-radius: 12px;
}

.permissions .group .groupTitle > :first-child {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 10px 10px 10px 0;
  font-size: 1rem;
  font-weight: bold;
}

.permissions .group .groupTitle:hover,
.permissions .group .groupTitle.active {
  background-color: #0C7997;
  color: #fff;
}

.permissions .group .groupTitle .arrow {
  margin-right: .5rem;
  font-size: 1.2rem;
  color: #333;
}

.permissions .group .groupTitle:hover .arrow,
.permissions .group .groupTitle.active .arrow {
  color: #fff;
}

.permissions .group .groupTitle .arrow.open {
  transform: rotate(90deg);
}

.permissions .group .groupTitle > div > span {
  flex-grow: 1;
  width: 1px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.permissions .group .groupTitle .groupSettings {
  position: absolute;
  top: 0;
  right: 8px;
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.permissions .group .groupTitle .groupSettings .groupSettingsIcon {
  font-size: 1.3rem;
}

.permissions .group .groupTitle .groupDelete,
.permissions .group .items .item .itemDelete {
  font-size: 1.3rem;
}

.permissions .group .groupTitle .settings {
  position: absolute;
  z-index: 5;
  top: 100%;
  right: 0;
  width: 140px;
  margin-top: 10px;
  padding: 8px 20px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 3px 10px rgba(0,0,0,0.1);
  box-sizing: border-box;
  cursor: default;
}

.permissions .group .groupTitle .settings.hidden {
  display: none;
}

.permissions .group .groupTitle .settings button {
  width: 100%;
  margin: 2px -10px;
  padding: 4px 10px;
  outline: none;
  user-select: none;
  cursor: pointer;
  border: 0;
  border-radius: 10px;
  box-sizing: content-box;
  background-color: transparent;
  font-size: .9375rem;
  font-weight: bold;
  color: #0C7997;
  text-align: left;
}

.permissions .group .groupTitle .settings button:hover {
  background-color: #F7F7F7;
}


.permissions .group .items .item {
  display: flex;
  align-items: center;
  margin: 5px 0 5px 1.7rem;
  padding: 0 10px;
  cursor: pointer;
  border-radius: 10px;
}

.permissions .group .items .item.noIndent {
  margin-left: 0 !important;
}

.permissions .group .items .item.selectedItem {
  background-color: #0C7997;
  color: #fff;
}

.permissions .group .items .item:not(.selectedItem):hover {
  background-color: #EFEFEF;
  color: #000;
}

.permissions .group .items .item .itemName {
  flex-grow: 1;
  width: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: .875rem;
  padding: .6rem .6rem .6rem 0;
}

.permissions .group .items .item .itemStatus {
  font-size: 1.2rem;
  cursor: pointer;
  color: #B60010;
}

.permissions .group .items .item .itemStatus.success {
  color: #1EBE5A;
}

.modal {
  width: 320px;
}

.modalText {
  margin-bottom: 25px;
  font-size: .85rem;
}

.modalInput {
  margin-bottom: 32px;
}

.modalInput.stacked {
  margin-bottom: 8px;
}

.modalButtons {}

.loader {
  display: flex !important;
  justify-content: center;
  width: 100% !important;
}

.busyLoader {
  height: 25px !important;
  margin-left: 10px !important;
}

.permissions .group .items .item.selectedItem > .busyLoader > div > div {
  background-color: #fff;
}

.noResults {
  display: block;
  padding: 10px 5px;
  text-align: center;
  font-size: .875rem;
  color: #A9A9A9;
}
