.container {
  text-decoration: none !important;
}

.title {
  display: flex;
  align-items: center;
  min-height: 40px;
  margin: 5px 0;
  padding: 0 10px;
  color: #333;
  cursor: pointer;
  border-radius: 18px;
}

.title .icon {
  margin: .125rem .5rem .125rem .8rem;
  font-size: 1.2rem;
  color: #333;
}

.container.active .icon {
  color: #fff;
}

.container.orphan .title .icon {
  margin-left: .4rem;
}

.title .name {
  flex-grow: 1;
  width: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: .875rem;
  line-height: 1.3rem;
  color: #333;
}

.container:not(.active) .title:hover {
  background-color: #fff;
}

.container.active .title {
  background-color: #0D7997;
}

.container.active .title .name {
  color: #fff;
}
