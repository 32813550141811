.container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  height: 40px;
  padding: 0 20px;
  cursor: pointer;
  border: 1px solid #0C7997;
  border-radius: 4px;
  background-color: #0C7997;
  box-sizing: border-box;
  color: #fff;
  font-size: .875rem;
  outline: none;
  user-select: none;
}

.container.disabled {
  cursor: default !important;
}

.container.link {
  text-decoration: none;
}

.container.transparent {
  border-color: transparent;
  background-color: transparent !important;
  color: #333;
}

.container.action {
  background-color: #F37646;
  border-color: #F37646;
  color: #fff;
}

.container.action.transparent {
  border-color: #F37646;
  color: #F37646;
}

.container:not(.disabled):not(.transparent):hover {
  opacity: .85;
}

.container:not(.disabled):not(.action).transparent:hover {
  color: #000;
}

.loader {
  height: 25px !important;
  margin-left: 10px !important;
}

.loader > div > div {
  background-color: #fff !important;
}

.container.transparent .loader > div > div {
  background-color: #333 !important;
}
