.container {
  width: 100%;
}

.container label {
  display: block;
  margin-bottom: .4rem;
  font-size: .75rem;
  font-weight: bold;
  color: #333;
}

.control {
  width: 100%;
  padding: 7.5px 15px;
  box-sizing: border-box;
  border: 1px solid #DFDFDF;
  border-radius: 3px;
  outline: none;
  background-color: #fff;
  font-size: .75rem;
  color: #333;
}

.container:not(.disabled) .control:hover {
  border-color: #A0A0A0;
}

.container:not(.disabled) .control:focus {
  border-color: #A0A0A0;
}

.container.disabled .control {
  background-color: #F4F5F7;
}

.control::placeholder {
  color: #B9B9B9;
}
.control::-ms-input-placeholder {
  color: #B9B9B9;
}

.preview {
  font-size: .75rem;
  color: #333;
}

.error {
  margin-top: .3rem;
  color: #B60010;
  font-size: .75rem;
}
