.container {
  display: flex;
  align-items: center;
  font-size: .875rem;
  line-height: 1.2rem;
  user-select: none;
}

.value {
  display: inline-flex;
  align-items: center;
  height: 1rem;
  line-height: 1rem;
  opacity: 0.7;
  color: #333;
}

.value.active {
  opacity: 1;
  font-weight: bold;
}

.value .link {
  text-decoration: none;
  color: #333;
}

.value:not(.active) .link:hover {
  text-decoration: underline;
}

.spacer {
  margin: 0 7px;
  font-size: 1rem;
  color: #333;
  opacity: 0.7;
}
