.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container *:not(input) {
  user-select: none;
}

.fields {
  width: 100%;
  margin-bottom: 32px;
}

.control:not(.nopad) {
  margin-bottom: 16px;
}

.control input {
  line-height: 16px !important;
  padding-top: 11px !important;
  padding-bottom: 11px !important;
}

.info {
  font-size: .8rem;
  line-height: .95rem;
  margin-bottom: 15px;
}

.info p {
  margin-bottom: 15px;
}