.container {
  width: 32px;
  height: 32px;
  /* border: 1px solid #B9B9B9; */
  border-radius: 50%;
  background-color: #fff;
  overflow: hidden;
}

.container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container span {
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 1.2rem;
  font-weight: bold;
  color: #111;
  letter-spacing: 1px;
  text-indent: 1px;
}
