:global([role="tooltip"] > div > div:first-child > div) {
  padding: 8px !important;
  box-shadow: 0 3px 10px rgba(0,0,0,0.2);
  border-radius: 4px;
  background-color: #0B7693 !important;
  color: #fff;
  font-size: .75rem;
  line-height: 20px;
}

:global([role="tooltip"]:not([data-tt-location="top"]) > div > div:last-child) {
  border-bottom-color: #0B7693 !important;
}

:global([role="tooltip"][data-tt-location="top"] > div > div:last-child) {
  border-top-color: #0B7693 !important;
}
