.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 25px 22px 32px 22px;
  box-sizing: border-box;
  background-color: #fff;
}

.globals {
  height: 86px;
  border-bottom: 1px solid #F0F0F0;
  margin-bottom: 25px;
  padding-bottom: 25px;
  box-sizing: border-box;
}

.bigTitle {
  position: relative;
  display: flex;
  align-items: center;
  height: 20px;
  margin-bottom: 24px;
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
  line-height: 1.25rem;
}

.justifyBetween {
  justify-content: space-between;
}

.filter {
  position: absolute;
  z-index: 2;
  top: calc(50% + 5px);
  right: 0;
  transform: translateY(-50%);
  user-select: none;
}

.filter .trigger {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  color: #0C7997;
  cursor: default;
}

.filter .trigger .triggerIcon {
  margin-right: 12px;
  font-size: 1.4rem;
}

.filter .trigger .triggerTitle {
  margin-right: 6px;
  font-size: .9375rem;
  white-space: nowrap;
}

.filter .trigger .triggerTotal {
  display: inline-block;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  font-size: .75rem;
  line-height: 21px;
  font-weight: normal;
  background-color: #0C7997;
}

.filter .items {
  position: absolute;
  z-index: 5;
  top: 100%;
  right: 0;
  width: 280px;
  padding: 15px 20px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 62px 0 rgba(0,0,0,0.09);
}

.filter .items .controls {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 18px;
  margin: 5px 0 15px 0;
}

.filter .items .controls button {
  padding: 0;
  outline: none;
  user-select: none;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  font-size: .9375rem;
  font-weight: bold;
  color: #0C7997;
}

.filter .items .itemRow label {
  padding: 5px 10px;
  margin: 0 -10px;
  border-radius: 10px;
  cursor: pointer;
}

.filter .items .itemRow:hover label {
  background-color: #F7F7F7;
}

.resources {
  height: 100%;
  position: relative;
}

.container.complete .resources {
  height: calc(100% - 155px);
}

.resources .resourcesActions {
  /* scrollable */
  position: relative;
  height: calc(100% - 37px);
  padding: 0 10px;
  margin-left: -20px !important;
  margin-right: -20px !important;
}

.resources .resourcesActions [data-scrollable-content] {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.arrow {
  margin-right: .5rem;
  font-size: 1.2rem;
  color: #333;
}

.arrow.open {
  transform: rotate(90deg);
}

.client {
  margin-bottom: 5px;
}

.client > .title {
  display: flex;
  align-items: center;
  height: 40px;
  margin: 0 -10px;
  padding: 0 10px;
  border-radius: 18px;
  background-color: #fff;
  color: #333;
  text-decoration: none;
  cursor: pointer;
}

.info {
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
}

.client > .title .logo {
  width: 1.75rem;
  height: 1.75rem;
  margin-right: .5rem;
}

.client > .title .name {
  flex-grow: 1;
  width: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.125rem;
  line-height: 1.6rem;
  color: #333;
}

.client.active > .title {
  background-color: #ebf9eb;
}

.buttonContainer {
  display: inline-flex;
}

.buttonContainer > button:first-child {
  margin-right: 10px;
}

.client > .title:hover {
  background-color: #F0F0F0;
  color: #000;
}

.project {
  margin-top: 5px;
}

.project.indented {
  margin-left: 20px;
}

.project > .title {
  display: flex;
  align-items: center;
  height: 40px;
  margin: 0 -10px;
  padding: 0 10px;
  background-color: #fff;
  border-radius: 18px;
  color: #333;
  text-decoration: none;
  cursor: pointer;
}

.project > .title .icon {
  margin: .125rem .5rem .125rem 0;
  font-size: 1.6rem;
  color: #333;
}

.project > .title .name {
  flex-grow: 1;
  width: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: .875rem;
  line-height: 1.3rem;
  color: #333;
}

.project.active > .title {
  background-color: #ebf9eb;
}

.project > .title:hover {
  background-color: #F0F0F0;
  color: #000;
}

.report {
  margin-top: 5px;
  margin-left: 20px;
}

.report > .title {
  display: flex;
  align-items: center;
  height: 40px;
  margin: 0 -10px;
  padding: 0 10px;
  border-radius: 18px;
  background-color: #fff;
  color: #333;
  cursor: default;
}

.report > .title .icon {
  margin: .125rem .5rem .125rem .8rem;
  font-size: 1.2rem;
  color: #333;
}

.report.orphan > .title .icon {
  margin-left: .4rem;
}

.report > .title .name {
  flex-grow: 1;
  width: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: .875rem;
  line-height: 1.3rem;
  color: #333;
}

.report.active > .title {
  background-color: #ebf9eb;
}

.report > .title:hover {
  background-color: #F0F0F0;
  color: #000;
}

.globalCheckboxes {
  display: flex;
}

.globalCheckboxes > * {
  width: auto;
  margin-right: 40px;
}

[data-checkbox-label] {
  font-size: .875rem;
  font-weight: normal;
}

.checkboxesTitles {
  display: flex;
  justify-content: flex-end;
  height: 13px;
  padding: 0 10px;
  margin-bottom: 24px;
  user-select: none;
  margin-left: 300px;
}

.checkboxesTitles .checkboxTitle.separator, .itemCheckbox.separator {
  margin-left: 80px;
}

.verticalSeparator {
  content: "";
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  width: 0px;
  height: 100%;
  border-left: 1px dashed #F37646;
}

.checkboxTitle > div {
  position: relative;
  padding-right: 26px;
  text-align: right;
  font-size: .8125rem;
  color: #333;
}

.checkboxTitle.noTooltip > div {
  padding-right: 0;
  text-align: center;
}

.checkboxTitle > div > div {
  display: block;
  position: absolute;
  top: calc(50% - 10px);
  right: 0;
  height: 20px;
  cursor: pointer;
}

.checkboxTitle > svg {
  color: #0C7997;
}

.checkboxTitle .tooltip {
  padding: 0 5px;
  white-space: nowrap;
  text-align: left;
}

.checkboxTitle .tooltip.fixed {
  width: 314px;
  white-space: normal;
}

.checkboxTitle .tooltip .title {
  margin: 15px 0 0px 0;
  font-size: .85rem;
  font-weight: bold;
}

.checkboxTitle .tooltip .title:first-child {
  margin-top: 5px;
}

.checkboxTitle .tooltip .item {
  position: relative;
  display: block;
  font-size: .8rem;
  margin: 5px 0 5px 20px;
}

.checkboxTitle .tooltip .item.clean {
  margin-left: 0;
}

.checkboxTitle .tooltip .item:not(.clean)::before {
  content: "\2022";
  position: absolute;
  top: 0;
  left: -20px;
  width: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 1rem;
}

.globalCheckboxes > *:not(.local) [data-checked], .itemCheckbox.scoped [data-checked] {
  border-color: #F37646 !important;
  background-color: #F37646 !important;
}

.itemCheckbox, .itemToggle, .checkboxesTitles .checkboxTitle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  min-width: 70px;
  max-width: 70px;
}

.itemCheckbox.scoped, .checkboxesTitles .checkboxTitle.scoped {
  width: 70px;
  min-width: 70px;
  max-width: 70px;
}

.itemCheckbox[data-checkbox-disabled] label {
  visibility: hidden;
}

.itemCheckbox label {
  justify-content: center;
}

.accessCheckbox {
  width: auto;
  margin-right: 8px;
}

.loaderContainer {
  padding: 25px 2rem 2rem 2rem;
}

.loaderContainer .loader {
  margin: 0 !important;
}

.loader {
  display: flex !important;
  justify-content: center;
  width: 100% !important;
  margin: 5px 0 !important;
}

.loader.left {
  justify-content: left;
  padding-left: 28px;
  box-sizing: border-box;
}

.noResults {
  display: block;
  padding: 10px 5px;
  text-align: center;
  font-size: .875rem;
  color: #A9A9A9;
}

.noResults.left {
  margin-left: 23px;
  text-align: left;
}

.modal {
  width: 320px;
}

.modalText {
  margin-bottom: 25px;
  font-size: .85rem;
}

.modalTextError {
  margin-bottom: 25px;
  font-size: .85rem;
  color: red;
}

.modalInput {
  margin-bottom: 32px;
}

.modalInput.stacked {
  margin-bottom: 8px;
}

.modalButtons {
  margin-top: 30px;
}

.loader {
  display: flex !important;
  justify-content: center;
  width: 100% !important;
}

.spanDescription {
  font-size: 11px;
  color: grey;
}

.busyLoader {
  height: 25px !important;
  margin-left: 10px !important;
}

.labelModal {
  display:flex;
  margin-top: 15px;
}

.nonClickable {
  background-color: rgba(100,100,100, 0.6);
  z-index: 100;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
