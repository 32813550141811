.container {}

.title {
  display: flex;
  align-items: center;
  min-height: 40px;
  margin: 5px 0;
  padding: 0 10px;
  color: #333;
  text-decoration: none;
  cursor: pointer;
  border-radius: 18px;
}

.title .arrow {
  margin-right: .5rem;
  font-size: 1.2rem;
  color: #333;
}

.title .arrow.open {
  transform: rotate(90deg);
}

.container.active .arrow {
  color: #fff;
}

.title .logo {
  width: 1.75rem;
  height: 1.75rem;
  margin-right: .5rem;
}

.title .name {
  flex-grow: 1;
  width: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.125rem;
  line-height: 1.6rem;
}

.container:not(.active) .title:hover {
  background-color: #fff;
}

.container.active .title {
  background-color: #0D7997;
}

.container.active .title .name {
  color: #fff;
}

.content {
  margin-left: 20px;
  /* border-bottom: 1px solid #B9B9B9; */
}

.orphans {
  /* border-top: 1px dashed #BBBBBB; */
}

.loader {
  display: flex !important;
  justify-content: center;
  width: 100% !important;
  margin: 5px 0 !important;
}

.noResults {
  display: block;
  padding: 10px 5px;
  text-align: center;
  font-size: .875rem;
  color: #A9A9A9;
}
