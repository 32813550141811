.container {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #444;
  opacity: .25;
}

.modal {
  position: fixed;
  z-index: 2001;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 320px;
  padding: 27px 32px 32px 32px;
  box-shadow: 0 1px 30px 5px rgba(0,0,0,0.09);
  border: 1px solid #F0F0F0;
  border-radius: 8px;
  background-color: #fff;
  font-size: .875rem;
  color: #333;
}

.modal .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.modal .header .title {
  flex-grow: 1;
  width: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.2rem;
  font-weight: bold;
}

.modal .header .close {
  margin-right: -5px;
  cursor: pointer;
  font-size: 1.4rem;
  color: #333;
}

.modal .close:hover {
  color: #000;
}
