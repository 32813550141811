.container {
  margin: 5px 0;
  border-radius: 18px;
}

.container:not(.active):hover {
  background-color: #fff;
}

.container.active {
  background-color: #0D7997;
}

.container a {
  text-decoration: none !important;
}

.button {
  display: flex;
  align-items: center;
  min-height: 40px;
  padding: 0 10px;
  color: #000;
  cursor: pointer;
}

.icon {
  display: none;
  margin: .125rem .5rem .125rem 1.1rem;
  font-size: 1.6rem;
  color: #BBBBBB;
}

.name {
  margin-left: .32rem;
  font-size: .9375rem;
  font-weight: bold;
  color: #0C7997;
}

.container.active .name {
  color: #fff;
}
