.container {
  height: 100%;
  position: relative;
  margin: 0 0 0 30px;
}

.actions {
  position: absolute;
  z-index: 3;
  right: 1rem;
  top: -5px;
  display: flex;
  align-items: center;
  transform: translateY(-100%);
}

.actions > :not(:first-child) {
  margin-left: .5rem;
}

.actions .deleteIcon {
  font-size: 1.3rem;
  margin-right: .2rem;
}

.actions .view {
  text-decoration: none !important;
}

.tabs {
  position: relative;
  z-index: 2;
  display: inline-flex;
  height: 46px;
  user-select: none;
  border-radius: 4px 4px 0 0;
  overflow: hidden;
  box-shadow: 0px -5px 14px -6px rgba(0,0,0,0.09);
}

.tab {
  padding: 1rem 36px;
  background-color: #F1F1F1;
  cursor: pointer;
  font-size: .875rem;
  font-weight: bold;
  color: #333;
  white-space: nowrap;
}

.tab:not(:first-child) {
  border-left: 1px solid #DEDEDE;
}

.tab.active + .tab {
  border-color: transparent;
}

.tab.active {
  border-color: transparent;
  border-radius: 4px 4px 0 0;
  background-color: #fff;
  color: #0D7997;
}

.tab:not(.active):hover {
  color: #000;
}

.section {
  height: calc(100% - 46px);
  position: relative;
  flex-grow: 1;
  background-color: #fff;
  padding: 2rem;
  box-sizing: border-box;
  box-shadow: 0 1px 62px 0 rgba(0,0,0,0.09);
}

.container.noTabs .section {
  height: 100%;
  border-radius: 5px 0 0 0;
}

.container.preview .section {
  width: 514px;
  border-radius: 5px 5px 0 0;
}

.section .title {
  display: flex;
  align-items: center;
  height: 16px;
  margin-bottom: 24px;
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  line-height: 1rem;
  white-space: nowrap;
}

.section .title.big {
  display: flex;
  height: 20px;
  font-size: 1.25rem;
  line-height: 1.25rem;
}

.section .title.mailing {
  margin-bottom: 3.51rem;
}

.section .textBlock {
  margin-bottom: 2rem;
  font-size: .875rem;
  line-height: 20px;
}

.section .textBlock.narrow {
  max-width: 485px;
  margin-bottom: 30px;
}

.section .textBlock.tall {
  height: 40px;
  max-height: 40px;
  margin-bottom: 50px;
}

.section .textBlock span {
  font-weight: bold;
}

.section .textBlock.split {
  display: flex;
  height: 20px;
  max-height: 20px;
}

.section .textBlock.split .left {
  flex-grow: 1;
}

.section .textBlock.split .right {
  display: flex;
  justify-content: center;
  width: 70px;
  min-width: 70px;
  max-width: 70px;
  margin-left: 14px;
}

.section .scrollableForm {
  /* scrollable */
  height: 100%;
}

.section .editor.sectioned .scrollableForm {
  margin-left: -46px !important;
  margin-right: -46px !important;
}

.section .editor.sectioned .scrollableForm [data-scrollable-content] {
  margin-left: 46px !important;
  margin-right: 46px !important;
}

.section .form {
  width: 100%;
  max-width: 520px;
}

.section .form [data-field-preview="true"] [data-field-label] {
  font-size: .8125rem !important;
}

.section .form [data-field-preview="true"] [data-field-value] {
  font-size: .875rem !important;
}

.section .form .formSection {
  margin-bottom: 2rem;
}

.section.details .editor .formSection.addressFields {
  margin-bottom: 0;
}

.section .form .formControl {
  margin-bottom: 1rem;
}

.section .form .formButtons {
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;
}

.section .form .formButtons > *:not(:first-child) {
  margin-left: 10px;
}

.section .form .controlsGroup {
  display: flex;
  justify-content: space-between;
}

.section .form .controlsGroup > * {
  width: calc(50% - 10px);
}

.section.details .editor.sectioned .formSection:not(.addressFields) {
  max-width: 240px;
  margin-bottom: 0;
}

.section.details .editor.sectioned .formSection.addressFields {
  max-width: 500px;
}

.section.details .editor.sectioned .formSection:not(.addressFields) .controlsGroup {
  flex-direction: column;
  justify-content: flex-start;
}

.section.details .editor.sectioned .formSection:not(.addressFields) .controlsGroup > * {
  width: 100%;
}

.section.details {
  display: flex;
  padding: 0;
}

.section.details .navigate {
  width: 310px;
  min-width: 310px;
  max-width: 310px;
  padding: 2rem;
  box-sizing: border-box;
  border-right: 1px solid #F0F0F0;
  background-color: #FBFBFD;
}

.section.details .navigate .navigateLinks {
  height: calc(100% - 134px);
}
.section.details .navigate .navigateLinks div {
  margin: 14px 0;
  user-select: none;
}

.section.details .navigate .navigateLinks span {
  font-size: 1rem;
  line-height: 28px;
  color: #333;
  font-weight: bold;
  cursor: default;
  opacity: .5;
  white-space: nowrap;
}

.section.details .navigate .navigateLinks div.active span {
  color: #0D7997;
  opacity: 1;
}

.section.details .editor {
  height: 100%;
  flex-grow: 1;
  padding: 2rem;
  box-sizing: border-box;
}

.section.details .editor.sectioned {
  padding: 5rem 3rem 2rem 3rem;
}

.section.details .editor .avatar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 16px;
}

.section.details .editor .avatar.centered {
  align-items: center;
}

.section.details .editor .avatar .avatarLabel {
  width: 100%;
  margin-bottom: 8px;
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  text-align: left;
}

.section.details .editor .avatar.centered .avatarLabel {
  text-align: center;
}

.section.details .editor .avatar .avatarUpload {
  position: relative;
  width: 82px;
  height: 82px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 1px solid #DFDFDF;
  background-color: #fff;
  overflow: hidden;
}

.section.details .editor .avatar .avatarUpload img {
  width: 100%;
  height: 100%;
}

.section.details .editor .avatar .avatarUpload .uploadOverlay {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #F7F7F7;
  opacity: .6;
  cursor: pointer;
}

.section.details .editor .avatar .avatarUpload.hasAvatar .uploadOverlay {
  background-color: #fff;
}

.section.details .editor .avatar .avatarUpload:hover .uploadOverlay {
  visibility: visible;
}

.section.details .editor .avatar .avatarUpload .uploadTrigger {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #0C7997;
  cursor: pointer;
}

.section.details .editor .avatar .avatarUpload.hasAvatar .uploadTrigger {
  visibility: hidden;
}

.section.details .editor .avatar .avatarUpload.hasAvatar:hover .uploadTrigger {
  visibility: visible;
}

.section.details .grant {
  width: 400px;
  min-width: 400px;
  max-width: 400px;
  height: 100%;
  padding: 2rem;
  box-sizing: border-box;
  border-left: 1px solid #F0F0F0;
  background-color: #FBFBFD;
}

.section.details .grant .grantActions {
  height: calc(100% - 44px);
}

.section.accounts {
  display: flex;
  padding: 0;
}

.section.accounts .userList {
  width: 325px;
  min-width: 325px;
  max-width: 325px;
  height: 100%;
  padding: 0 2rem 2rem 2rem;
  border-right: 1px solid #F0F0F0;
  box-sizing: border-box;
}

.section.accounts .userList .usersActions {
  height: calc(100% - 144px);
}

.section.accounts .userList .usersActions.tall {
  height: calc(100% - 89px);
}

.section.accounts .userList .domainsActions {
  height: calc(100% - 89px);
}

.section.accounts .anyActions .templatesActions {
  height: calc(100% - 62px);
  margin: 0 -10px;
}

.section.accounts .anyActions .domainsTexts {
  /* scrollable */
  height: 100%;
}

.section.accounts .userActions {
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
}

.section.accounts .anyActions {
  height: 100%;
  flex-grow: 1;
  overflow: hidden;
  padding: 2rem;
  box-sizing: border-box;
}

.accountsTabs {
  margin-bottom: 24px;
}

.accountsTabs .template {
  margin: 0 -2rem;
  padding: 19px 32px;
  border-bottom: 1px solid #F0F0F0;
  height: 55px;
  box-sizing: border-box;
  user-select: none;
}

.accountsTabs .template span {
  display: block;
  margin: -9px -10px;
  padding: 9px 10px;
  border-radius: 14px;
  font-size: .9375rem;
  line-height: 17px;
  font-weight: bold;
  color: #0C7997;
  cursor: pointer;
  white-space: nowrap;
}

.accountsTabs .template:not(.active) span:hover {
  background-color: #EFEFEF;
}

.accountsTabs .template.active span {
  background-color: #0D7997;
  color: #fff;
}

.innerTabs {
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
  height: 65px;
  padding: 1rem 2rem 0 2rem;
  box-sizing: border-box;
  border-bottom: 1px solid #F0F0F0;
  user-select: none;
}

.innerTabs.stretch {
  margin: 0 -2rem;
}

.innerTab {
  position: relative;
  padding-bottom: 10px;
  background-color: #fff;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  color: #555;
  white-space: nowrap;
}

.innerTabs.stretch .innerTab {
  font-size: 1.25rem;
}

.innerTab:not(:last-child) {
  margin-right: 30px;
}

.innerTab:hover {
  color: #000;
}

.innerTab.active {
  color: #0D7997;
}

.innerTab.active::after {
  content: "";
  position: absolute;
  top: calc(100% + -1px);
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #0D7997;
}

.innerContent {
  height: calc(100% - 65px);
  box-sizing: border-box;
}

.innerContent.spaced {
  padding: 25px 2rem 2rem 2rem;
}

.loader {
  display: flex !important;
  justify-content: center;
  width: 100% !important;
}

.noUser {
  display: block;
  padding: 10px 5px;
  text-align: center;
  font-size: .875rem;
  color: #A9A9A9;
}

.container_over {
  position: absolute;
  z-index: 999;
  top: 0;
  background: #fff;
  width: 100%;
}

.avatarError {
  margin-top: .3rem;
  color: #B60010;
  font-size: .75rem
}
